import type { VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { mdiLoading } from '@mdi/js';
import Icon from '@mdi/react';
import { cva } from 'class-variance-authority';
import { cn } from '@srcTailwindUi/lib/utils';
const spinnerVariants = cva('', {
  variants: {
    variant: {
      primary: 'text-white',
      secondary: 'text-blue-600',
      lightGray: 'text-gray-30'
    },
    size: {
      default: 'h-6 w-6',
      sm: 'h-4 w-4',
      lg: 'h-8 w-8'
    }
  },
  defaultVariants: {
    variant: 'primary',
    size: 'default'
  }
});
type SpinnerProps = {
  fullHeight?: boolean;
  className?: string;
} & VariantProps<typeof spinnerVariants>;
const Spinner = ({
  fullHeight,
  className,
  variant,
  size,
  ...props
}: SpinnerProps) => {
  const spinner = <Icon path={mdiLoading} {...props} className={cn(spinnerVariants({
    variant,
    size,
    className
  }))} spin />;
  return fullHeight ? <div className="absolute left-0 top-0 flex h-screen w-screen items-center justify-center">
      {spinner}
    </div> : spinner;
};
export { Spinner, spinnerVariants, type SpinnerProps };