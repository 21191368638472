import type { ReadonlyURLSearchParams } from 'next/navigation';

export const stringifyUrl = (input: {
  href: string;
  searchParams?: ReadonlyURLSearchParams | null;
}) => {
  const { href, searchParams } = input;

  const [pathname, queryString] = href.split('?');

  const utm = new URLSearchParams(
    getUtmSearchParams(searchParams ?? undefined)
  );

  const query = new URLSearchParams(queryString);

  for (const [key, value] of utm.entries()) {
    query.set(key, value);
  }

  const newQuery = query.toString() ? `?${query.toString()}` : '';

  return `${pathname}${newQuery}`;
};

export const getUtmString = (searchParams?: ReadonlyURLSearchParams | null) => {
  const utmString = getUtmSearchParams(searchParams).toString();
  return utmString ? `?${utmString}` : '';
};

export const getUtmSearchParams = (
  searchParams?: ReadonlyURLSearchParams | null
) => {
  const utm_source = searchParams?.get('utm_source');
  const utm_medium = searchParams?.get('utm_medium');
  const utm_campaign = searchParams?.get('utm_campaign');
  const utm_term = searchParams?.get('utm_term');
  const utm_content = searchParams?.get('utm_content');

  const utmParams = new URLSearchParams({
    ...(utm_source ? { utm_source } : {}),
    ...(utm_medium ? { utm_medium } : {}),
    ...(utm_campaign ? { utm_campaign } : {}),
    ...(utm_term ? { utm_term } : {}),
    ...(utm_content ? { utm_content } : {}),
  });

  return utmParams;
};
